import React from 'react';
import './App.css';

class RotateIMG extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      rotation: 0,
      latitude:0,
      longitude:0
    }

    this.getLocation = this.getLocation.bind(this,
      (newRotation,newLatitude,newLongitude)=>{this.setState({
      rotation: newRotation})})

  }

  getLocation(changeDir){

      const success = (position) =>{
        let latitude = position.coords.latitude;
        let longitude= position.coords.longitude;

        fetch(`https://api.aladhan.com/v1/qibla/${latitude}/${longitude}`, {
          method: 'get',
        }).then(function(response) {
          response.json().then(function(data) {
            console.log(data.data);
            changeDir(data.data.direction)
          });
        }).catch(function (error) {
          console.log("Something went wrong. Please try again later.");
        });

  }

      function error() {
    console.log('Unable to retrieve your location');;
  }

  if(!navigator.geolocation) {
    console.log('Geolocation is not supported by your browser');
  } else {
    navigator.geolocation.getCurrentPosition(success, error);
  }


  }


  render(){
    const { rotation } =  this.state;
    return <div><input onClick={this.getLocation} type="button" value="Поиск киблы" />
    <div compass style={{
      backgroundImage: `url("https://sun1.is74.userapi.com/TvQ9YBkO-Tg93ibJksGhjqyz-Wydso4I-qe3Tg/xX3T7d2j1DU.jpg)`,color:'white'
    }}>
    <img style={{transform: `rotate(${rotation}deg)`}} src="./needle.png" width="320px" height="320px" />
</div>
    </div>

  }
};




function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RotateIMG/>
      </header>
    </div>
  );
}




export default App;
